body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=text]::-ms-clear {
  display: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #757575;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #757575;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #757575;
}
:-moz-placeholder { /* Firefox 18- */
  color: #757575;
}

.contactPlaceholder input::-webkit-input-placeholder {
  color: #d1d1d1;
}
.contactPlaceholder input:-moz-placeholder {
  /* Firefox 18- */
  color: #d1d1d1;
}
.contactPlaceholder input::-moz-placeholder {
  /* Firefox 19+ */
  color: #d1d1d1;
}
.contactPlaceholder input:-ms-input-placeholder {
  color: #d1d1d1;
}

@keyframes horizontalStatusBar {
  from {left: 0%;}
  to {left: 100%;}
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #e2e2e2;
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.loginPopup {
  position: fixed;
  z-index: 10;
  left: 50%;
  top: 30%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: top 1s, opacity 0.4s;
}

.loginOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0, 0, 0);
  opacity: 0;
  transition: opacity 0.4s;
}

.pause-animation {
  animation-play-state: paused !important;
}

.everlib-logo-first-bar,
.everlib-logo-second-bar,
.everlib-logo-third-bar,
.everlib-logo-fourth-bar {
  display: inline-block;
  /* border-radius: 4px; */
  width: 9px;
  transition: 0.5s;
  animation: equalize 3s 0s infinite;
  animation-timing-function: linear;
  position: absolute;
  bottom: 0;
}
.everlib-logo-first-bar {
  height: 17px;
  left: 0;
  transition: 0.5s;
  animation-delay: -1.9s;
}
.everlib-logo-second-bar {
  height: 10px;
  left: 10px;
  animation-delay: -2.9s;
}
.everlib-logo-third-bar {
  height: 18px;
  left: 20px;
  animation-delay: -3.9s;
}
.everlib-logo-fourth-bar {
  height: 15px;
  left: 30px;
  animation-delay: -4.9s;
}
@keyframes equalize {
  0% {
    height: 19px;
  }
  4% {
    height: 17px;
  }
  8% {
    height: 15px;
  }
  12% {
    height: 4px;
  }
  16% {
    height: 18px;
  }
  20% {
    height: 9px;
  }
  24% {
    height: 19px;
  }
  28% {
    height: 1px;
  }
  32% {
    height: 19px;
  }
  36% {
    height: 18px;
  }
  40% {
    height: 9px;
  }
  44% {
    height: 15px;
  }
  48% {
    height: 19px;
  }
  52% {
    height: 12px;
  }
  56% {
    height: 1px;
  }
  60% {
    height: 8px;
  }
  64% {
    height: 19px;
  }
  68% {
    height: 12px;
  }
  72% {
    height: 18px;
  }
  76% {
    height: 6px;
  }
  80% {
    height: 19px;
  }
  84% {
    height: 16px;
  }
  88% {
    height: 19px;
  }
  92% {
    height: 6px;
  }
  96% {
    height: 19px;
  }
  100% {
    height: 19px;
  }
}
